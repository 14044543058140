import styled from 'styled-components';
import { media } from '@luigiclaudio/ga-baseline-ui/layout';
import { rem } from 'polished';

const CurrentProductTitleContainer = styled.span`
    display: block;
    margin: 0 auto ${rem(40)};
    max-width: ${({ maxWidth }) => rem(maxWidth)};

    ${media.lg`
        max-width: none;
        margin: 0 0 0 6vw;
    `};
`;

export default CurrentProductTitleContainer;

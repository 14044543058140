import styled from 'styled-components';
import { rem } from 'polished';
import { h4Styles } from '@luigiclaudio/ga-baseline-ui/typography';

const CurrentProductTitleHeaderRetailer = styled.span.attrs(() => ({
    colorValue: 'muted',
}))`
    ${h4Styles}
    margin-left: ${rem(8)};
`;

export default CurrentProductTitleHeaderRetailer;

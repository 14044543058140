import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useIntl } from 'react-intl';
import { Loader, useHasMounted, HelmetDetails } from '@luigiclaudio/ga-baseline-ui/helpers';
import { ThemeModeContext } from '@luigiclaudio/ga-baseline-ui/theme-provider';
import { SiteLaunchTemplate } from '@luigiclaudio/ga-baseline-ui/site-launch';
import useCurrentProduct from '../utils/useCurrentProduct';
import SiteFooter from '../components/pageLayout/SiteFooter';
import IntroCustomBg from '../components/marketing/IntroCustomBg';
import CurrentProductImage from '../components/app/currentProduct/CurrentProductImage';
import CurrentProductTitleDetails from '../components/app/currentProduct/CurrentProductTitleDetails';
import useCreateProductInLocation from '../services/useCreateProductInLocation';
import ProductSuggestionList from '../components/app/ProductSuggestionList';

const ShowProductPage = () => {
    const intl = useIntl();
    const theme = useContext(ThemeContext);
    const hasMounted = useHasMounted();
    const { currentProduct } = useCurrentProduct();
    const { themeMode } = useContext(ThemeModeContext);

    useCreateProductInLocation();

    const imageHeight = theme.site.productItem.oneColumn.height * 1.25;
    const imageWidth = theme.site.productItem.oneColumn.width * 1.5;

    const viewportHeight = 69;
    const renderBgColor = hasMounted ? theme.site.stage.backgroundColor : null;

    if (!currentProduct && hasMounted) {
        return <Loader isFullScreen fullScreenBackgroundColor={theme.site.stage.backgroundColor} />;
    }

    if (!currentProduct) {
        return null;
    }

    const { image, title, brand, retailer, price, currency, intlKeywords } = currentProduct;

    return (
        <>
            <HelmetDetails
                title={intl.formatMessage(
                    { id: 'helmet.showProduct.title' },
                    {
                        brand: brand.toUpperCase(),
                        title: title.toUpperCase(),
                        retailer: intl.formatMessage({ id: `retailer.${retailer}` }),
                    },
                )}
                priceAmount={intl.formatNumber(price, {
                    minimumFractionDigits: 2,
                })}
                priceCurrency={currency}
                type="product"
                keywords={intlKeywords.toString()}
                siteImage={image}
            />
            <SiteLaunchTemplate
                bgStart={renderBgColor}
                bgEnd={renderBgColor}
                customTitle={
                    <CurrentProductTitleDetails
                        currentProduct={currentProduct}
                        maxWidth={imageWidth}
                    />
                }
                customBg={
                    <IntroCustomBg
                        product={currentProduct}
                        viewportHeight={viewportHeight}
                        themeMode={{ themeMode }}
                    />
                }
                viewportHeight={viewportHeight}
                aside={<CurrentProductImage imageWidth={imageWidth} imageHeight={imageHeight} />}
                alignMainItems="center"
                content={<ProductSuggestionList product={currentProduct} />}
                footer={<SiteFooter />}
            />
        </>
    );
};

export default ShowProductPage;

import styled from 'styled-components';
import { rem } from 'polished';
import productItemOverlayStyles from '../../../../styles/productItemOverlayStyles';

const CurrentProductImageContainer = styled.div`
    backface-visibility: hidden;
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.md)};
    box-shadow: ${({ theme }) => theme.base.boxShadow.elevationDefault};
    height: ${({ $height }) => rem($height)};
    margin: 0 auto;
    max-width: ${({ $width }) => rem($width)};
    overflow: hidden;
    position: relative;
    width: 100%;
    -webkit-perspective: 1000;

    &:before {
        ${productItemOverlayStyles}
        content: '';
    }
`;

export default CurrentProductImageContainer;

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ProgressiveBg, TransitionItem } from '@luigiclaudio/ga-baseline-ui/helpers';
import { ThemeModeContext } from '@luigiclaudio/ga-baseline-ui/theme-provider';
import IntroCustomBgContainer from './IntroCustomBgContainer';
import productPropTypes from '../../../prop-types/productPropTypes';

const IntroCustomBg = ({ product, viewportHeight }) => {
    if (!product) {
        return null;
    }

    const [inProp, setInProp] = useState(false);
    const { themeMode } = useContext(ThemeModeContext);
    const { image } = product;

    useEffect(() => {
        setInProp(false);
        setTimeout(() => {
            setInProp(true);
        }, 100);
    }, [themeMode]);

    return (
        <IntroCustomBgContainer viewportHeight={viewportHeight} themeMode={themeMode}>
            <TransitionItem appear inProp={inProp} isFullHeight>
                <ProgressiveBg image={image} style={{ minHeight: `${viewportHeight}vh` }} />
            </TransitionItem>
        </IntroCustomBgContainer>
    );
};

IntroCustomBg.propTypes = {
    product: productPropTypes.product.isRequired,
    viewportHeight: PropTypes.number.isRequired,
};

export default IntroCustomBg;

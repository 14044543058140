import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useMeasure } from '@luigiclaudio/ga-baseline-ui/helpers';
import { ProductFeature } from '@luigiclaudio/ga-baseline-ui/commerce';
import { ThemeModeContext } from '@luigiclaudio/ga-baseline-ui/theme-provider';
import ProductItem from '../ProductItem';
import ProductSuggestionContainer from './ProductSuggestionContainer';
import ProductSuggestionItemContainer from './ProductSuggestionItemContainer';
import ProductSuggestionListContainer from './ProductSuggestionListContainer';
import productPropTypes from '../../../prop-types/productPropTypes';
import BrandTitle from '../BrandTitle';
import useAllProductUserCache from '../../../services/useAllProductUserCache';
import handleOnHotClick from '../../../utils/handleOnHotClick';

const ProductSuggestion = ({ suggestionList, suggestionBrand }) => {
    const [bind, bounds] = useMeasure();
    const theme = useContext(ThemeContext);
    const { themeMode } = useContext(ThemeModeContext);
    const {
        allProductsUserFromClient,
        setAllProductsUserFromClient,
        createProduct,
        deleteProduct,
        user,
        pathname,
        client,
    } = useAllProductUserCache();

    const products = allProductsUserFromClient?.allProducts?.data;

    const productItemHeight = theme.site.productItem.oneColumn.height;
    const productItemWidth = theme.site.productItem.oneColumn.width;
    const { maxSiteWidth } = theme.layout;

    const productItemsPerRow =
        Math.floor(Math.min(bounds.width, maxSiteWidth) / productItemWidth) * 2;

    const suggestionListWithLimit = [...suggestionList.slice(0, Math.floor(productItemsPerRow))];

    return (
        <ProductSuggestionContainer {...bind}>
            <ProductFeature
                isTablet
                alignItems="center"
                alternatePosition
                subtitle={
                    <FormattedMessage
                        id="suggestion.byBrand.title"
                        values={{
                            brand: <BrandTitle>{suggestionBrand}</BrandTitle>,
                        }}
                    />
                }
                verticalSpacing={2}
            />
            <ProductSuggestionListContainer>
                {suggestionListWithLimit.map((product, index) => {
                    const isHot = products?.find((productUser) => productUser.url === product.url);

                    const {
                        title,
                        brand,
                        url,
                        image,
                        price,
                        priceBeforeSale,
                        currentLocalPrice,
                        intlKeywords,
                        retailer,
                        group,
                        currency,
                    } = product;

                    return (
                        <ProductSuggestionItemContainer key={index}>
                            <ProductItem
                                rowIndex={0}
                                brand={brand}
                                url={url}
                                title={title}
                                image={image}
                                price={price}
                                priceBeforeSale={priceBeforeSale}
                                currentLocalPrice={currentLocalPrice}
                                intlKeywords={intlKeywords}
                                retailer={retailer}
                                group={group}
                                currency={currency}
                                itemWidth={productItemWidth}
                                itemHeight={productItemHeight}
                                themeMode={themeMode}
                                isHotId={isHot?.id}
                                onHotClick={(currentUrl, currentId) =>
                                    handleOnHotClick({
                                        currentUrl,
                                        currentId,
                                        currentProduct: suggestionListWithLimit.find(
                                            (suggestionListItem) =>
                                                suggestionListItem.url === currentUrl,
                                        ),
                                        setAllProductsUserFromClient,
                                        allProductsUserFromClient,
                                        client,
                                        createProduct,
                                        deleteProduct,
                                        user,
                                        pathname,
                                    })
                                }
                            />
                        </ProductSuggestionItemContainer>
                    );
                })}
            </ProductSuggestionListContainer>
        </ProductSuggestionContainer>
    );
};

ProductSuggestion.propTypes = {
    suggestionList: productPropTypes.products.isRequired,
    suggestionBrand: PropTypes.string.isRequired,
};

export default ProductSuggestion;

import React from 'react';
import PropTypes from 'prop-types';
import { ProgressiveBg, SrLabel } from '@luigiclaudio/ga-baseline-ui/helpers';
import { A } from '@luigiclaudio/ga-baseline-ui/typography';
import CurrentProductImageContainer from './CurrentProductImageContainer';
import ProductItemActions from '../../ProductItemActions';
import useCurrentProduct from '../../../../utils/useCurrentProduct';
import handleOnHotClick from '../../../../utils/handleOnHotClick';
import useAllProductUserCache from '../../../../services/useAllProductUserCache';

const CurrentProductImage = ({ imageHeight, imageWidth }) => {
    const { currentProduct } = useCurrentProduct();

    const {
        allProductsUserFromClient,
        setAllProductsUserFromClient,
        createProduct,
        deleteProduct,
        user,
        pathname,
        client,
    } = useAllProductUserCache();

    const {
        brand,
        title,
        url,
        image,
        retailer,
        group,
        price,
        priceBeforeSale,
        currentLocalPrice,
        intlKeywords,
        currency,
    } = currentProduct;

    const userProduct = {
        brand,
        title,
        url,
        image,
        retailer,
        group,
        price,
        priceBeforeSale,
        currentLocalPrice,
        intlKeywords,
        currency,
    };

    const isHot = allProductsUserFromClient?.allProducts?.data?.find(
        (product) => product.url === url,
    );

    if (isHot) {
        userProduct.id = isHot.id;
    }

    return (
        <CurrentProductImageContainer $height={imageHeight} $width={imageWidth}>
            <A path={url}>
                <SrLabel as="span">{title}</SrLabel>
                <ProgressiveBg image={image} />
            </A>
            <ProductItemActions
                userProduct={userProduct}
                onHotClick={(currentUrl, currentId) =>
                    handleOnHotClick({
                        currentUrl,
                        currentId,
                        currentProduct,
                        setAllProductsUserFromClient,
                        allProductsUserFromClient,
                        client,
                        createProduct,
                        deleteProduct,
                        user,
                        pathname,
                    })
                }
            />
        </CurrentProductImageContainer>
    );
};

CurrentProductImage.propTypes = {
    imageHeight: PropTypes.number.isRequired,
    imageWidth: PropTypes.number.isRequired,
};

export default CurrentProductImage;

import styled from 'styled-components';
import { rem } from 'polished';
import { h1Styles } from '@luigiclaudio/ga-baseline-ui/typography';

const CurrentProductTitle = styled.span`
    ${h1Styles}
    margin: ${rem(8)} 0;
    text-transform: capitalize;
`;

export default CurrentProductTitle;

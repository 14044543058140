import React from 'react';
import ProductSuggestion from './ProductSuggestion';
import useBrandProductSuggestion from '../../../utils/useBrandProductSuggestion';
import productPropTypes from '../../../prop-types/productPropTypes';

const ProductSuggestionList = ({ product }) => {
    const { suggestionByBrand, suggestionBrand } = useBrandProductSuggestion(product);

    if (!suggestionByBrand) {
        return null;
    }

    return (
        <ProductSuggestion suggestionList={suggestionByBrand} suggestionBrand={suggestionBrand} />
    );
};

ProductSuggestionList.propTypes = {
    product: productPropTypes.product.isRequired,
};

export default ProductSuggestionList;

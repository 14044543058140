import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { AnchorButton } from '@luigiclaudio/ga-baseline-ui/button';
import { GatsbyA, A } from '@luigiclaudio/ga-baseline-ui/typography';
import { GaIcon } from '@luigiclaudio/ga-baseline-ui/icon';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import BrandTitle from '../../BrandTitle';
import CurrentProductDiscountedPriceLabel from './CurrentProductDiscountedPriceLabel';
import CurrentProductIPriceContainer from './CurrentProductIPriceContainer';
import CurrentProductTitleHeader from './CurrentProductTitleHeader';
import CurrentProductTitleHeaderRetailer from './CurrentProductTitleHeaderRetailer';
import CurrentProductTitleBrand from './CurrentProductTitleBrand';
import CurrentProductTitleBrandIcon from './CurrentProductTitleBrandIcon';
import CurrentProductTitle from './CurrentProductTitle';
import CurrentProductPriceLabel from './CurrentProductPriceLabel';
import CurrentProductTitleContainer from './CurrentProductTitleContainer';
import brandUtils from '../../../../utils/brandUtils';
import productPropTypes from '../../../../prop-types/productPropTypes';
import BrandContext from '../../../context/BrandContext';

const CurrentProductTitleDetails = ({ currentProduct, maxWidth }) => {
    const { state } = useLocation();
    const { setProductListCurrentRowIndex } = useContext(BrandContext);

    const intl = useIntl();
    const {
        brand,
        title,
        url,
        priceBeforeSale,
        price,
        currency,
        retailer,
        country,
    } = currentProduct;
    const priceFormatOptions = brandUtils.priceFormatOptions(currency);

    const renderPrice = !price ? (
        <CurrentProductPriceLabel>
            <FormattedNumber value={priceBeforeSale} {...priceFormatOptions} />
        </CurrentProductPriceLabel>
    ) : (
        <>
            <CurrentProductPriceLabel>
                <FormattedNumber value={price} {...priceFormatOptions} />
            </CurrentProductPriceLabel>
            <CurrentProductDiscountedPriceLabel>
                <FormattedNumber value={priceBeforeSale} {...priceFormatOptions} />
            </CurrentProductDiscountedPriceLabel>
        </>
    );

    return (
        <CurrentProductTitleContainer maxWidth={maxWidth}>
            <CurrentProductTitleHeader>
                <GatsbyA
                    to={`/${brandUtils.getPathToBrand(brand, country)}`}
                    onClick={() => {
                        if (state?.isCategory) {
                            setProductListCurrentRowIndex(undefined);
                        }
                    }}
                    color="dark"
                >
                    <CurrentProductTitleBrand>
                        <CurrentProductTitleBrandIcon>
                            <GaIcon icon="arrow-left" size={20} />
                        </CurrentProductTitleBrandIcon>
                        <BrandTitle>{brand}</BrandTitle>
                    </CurrentProductTitleBrand>
                </GatsbyA>
                {retailer !== brand && (
                    <CurrentProductTitleHeaderRetailer>
                        <BrandTitle>
                            <FormattedMessage id={`retailer.${retailer}`} />
                        </BrandTitle>
                    </CurrentProductTitleHeaderRetailer>
                )}
            </CurrentProductTitleHeader>
            <CurrentProductTitle>
                <A path={url} btnType="link" color="dark">
                    {title}
                </A>
            </CurrentProductTitle>
            <CurrentProductIPriceContainer>{renderPrice}</CurrentProductIPriceContainer>
            <AnchorButton path={url} btnType="outlined" skin="primary" size="lg">
                <FormattedMessage
                    id="showProduct.shopNow.button.label"
                    values={{ retailer: intl.formatMessage({ id: `retailer.${retailer}` }) }}
                />
            </AnchorButton>
        </CurrentProductTitleContainer>
    );
};

CurrentProductTitleDetails.propTypes = {
    currentProduct: productPropTypes.product.isRequired,
    maxWidth: PropTypes.number.isRequired,
};

export default CurrentProductTitleDetails;

import styled from 'styled-components';
import { rem } from 'polished';
import { h4Styles } from '@luigiclaudio/ga-baseline-ui/typography';

const CurrentProductDiscountedPriceLabel = styled.span.attrs(() => ({
    colorValue: 'muted',
}))`
    ${h4Styles}
    padding-left: ${rem(8)};
    text-decoration: line-through;
`;

export default CurrentProductDiscountedPriceLabel;

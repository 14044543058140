import styled from 'styled-components';
import { h4Styles } from '@luigiclaudio/ga-baseline-ui/typography';

const CurrentProductTitleBrand = styled.span`
    ${h4Styles}
    align-items: center;
    display: flex;
`;

export default CurrentProductTitleBrand;

import brandUtils from './brandUtils';

const productBrandUtils = {
    getInitialProduct: async (brand, country) => {
        const getBrand = async () => {
            const response = await brandUtils.brandApi(brandUtils.getBrandSlug(brand), country);

            const getCurrentProduct =
                response.productsByRetailer[
                    Math.floor(Math.random() * (response.productsByRetailer.length - 0) + 0)
                ];

            return getCurrentProduct;
        };

        return getBrand();
    },
};

export default productBrandUtils;

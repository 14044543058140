import styled from 'styled-components';

const IntroCustomBgContainer = styled.div`
    filter: ${({ themeMode }) =>
        `invert(${themeMode === 'lightMode' ? 0 : 1}) blur(8px) opacity(0.2)`};
    height: 100%;
    left: 0;
    min-height: ${({ viewportHeight }) => `${viewportHeight}vh`};
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
`;

export default IntroCustomBgContainer;

import { useEffect, useContext } from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import brandUtils from './brandUtils';
import BrandContext from '../components/context/BrandContext';
import productBrandUtils from './productBrandUtils';
import appConfig from '../data/appConfig.json';
import initialProductBrandList from '../data/initialProductBrandList.json';

const useCurrentProduct = () => {
    const location = useLocation();
    const { countries } = appConfig;

    const currentLocation = new URLSearchParams(location.search);
    const currentLocationProductBrand = currentLocation.get('brand');
    const currentLocationProductUrl = currentLocation.get('url');

    const { productOnStage, setProductOnStage } = useContext(BrandContext);

    useEffect(() => {
        if (currentLocationProductBrand && currentLocationProductUrl) {
            const getBrand = async () => {
                const response = await brandUtils.brandApi(
                    brandUtils.getBrandSlug(currentLocationProductBrand),
                    countries[0],
                );
                const currentProduct = response.productsByRetailer.find((product) =>
                    product.url.includes(currentLocationProductUrl),
                );

                setProductOnStage(currentProduct);
            };
            getBrand();
        }

        if (
            !productOnStage &&
            (!currentLocationProductBrand ||
                currentLocationProductBrand === 'undefined' ||
                !currentLocationProductUrl ||
                currentLocationProductUrl === 'undefined')
        ) {
            const getInitialProduct = async () => {
                const initialProduct = await productBrandUtils.getInitialProduct(
                    initialProductBrandList[
                        Math.floor(Math.random() * (initialProductBrandList.length - 0) + 0)
                    ],
                    countries[0],
                );
                navigate(`?brand=${initialProduct.brand}&url=${initialProduct.url}`);
            };
            getInitialProduct();
        }
    }, [currentLocationProductBrand, currentLocationProductUrl]);

    return { currentProduct: productOnStage };
};

export default useCurrentProduct;

import { rem } from 'polished';
import styled from 'styled-components';

const ProductSuggestionItemContainer = styled.div`
    height: ${({ theme }) => rem(theme.site.productItem.oneColumn.height)};
    margin: ${({ theme }) => rem(theme.site.productItem.oneColumn.gapV)} 0;
    width: ${({ theme }) => rem(theme.site.productItem.oneColumn.width)};
`;

export default ProductSuggestionItemContainer;

import { useState, useEffect } from 'react';
import brandUtils from './brandUtils';
import appConfig from '../data/appConfig.json';

const PRODUCT_LIST_AMOUNT_LIMIT = 16;

const useBrandProductSuggestion = (product) => {
    const { brand, intlKeywords } = product;
    const { countries } = appConfig;

    const [suggestionByBrand, setSuggestionByBrand] = useState();

    useEffect(() => {
        const getBrand = async () => {
            const response = await brandUtils.brandApi(
                brandUtils.getBrandSlug(brand),
                countries[0],
            );

            const similarProducts = response.productsByRetailer.filter((responseProduct) => {
                return responseProduct.intlKeywords.some((intlKeyword) =>
                    intlKeywords.includes(intlKeyword),
                );
            });

            setSuggestionByBrand(similarProducts.slice(0, PRODUCT_LIST_AMOUNT_LIMIT));
        };

        getBrand();
    }, [product]);

    return { suggestionByBrand, suggestionBrand: brand };
};

export default useBrandProductSuggestion;

import { rem } from 'polished';
import styled from 'styled-components';

const CurrentProductIPriceContainer = styled.span`
    align-items: flex-start;
    display: flex;
    margin-bottom: ${rem(16)};
`;

export default CurrentProductIPriceContainer;

import styled from 'styled-components';
import { rem } from 'polished';

const ProductSuggestionContainer = styled.div`
    margin: 0 auto;
    max-width: ${({ theme }) => rem(theme.layout.maxSiteWidth)};
    padding-bottom: ${rem(32)};
`;

export default ProductSuggestionContainer;
